export const INDUSTRIES = {
  automotive: "Automotive",
  manufacturing: "Manufacturing, Electronics and Engineering",
  construction: "Construction",
  consumer_goods: "Consumer Goods",
  energy: "Energy and Utilities",
  media: "Media, Entertainment and Culture",
  government: "Government",
  pharmaceutics: "Pharmaceutical and Healthcare",
  travel: "Travel and Hospitality",
  financial_services: "Banking & Financial Services",
  non_profit: "Not For Profit",
  professional_services: "Professional and Business Services",
  retail: "Retail and Fashion",
  tech: "Technology",
  telecoms: "Telecommunications",
  transportation: "Transportation and Logistics",
  others: "Others",
};

export const DOMAIN_TYPES = {
  details: "Company Overview",
  culture: "Culture",
  organisation: "Organisation & People",
  tech: "Platforms & Tooling",
  governance: "Data Governance & Management",
  business: "Business Value",
  risk: "Risk & Security",
  innovation: "Innovation",
  strategy: "Strategic Priorities",
};

export const DOMAIN_ORDER = [
  "details",
  "culture",
  "organisation",
  "tech",
  "governance",
  "business",
  "risk",
  "innovation",
  "strategy",
];
