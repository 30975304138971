import React, { lazy, Suspense, useEffect } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";
import TestingFlag from "./components/TestingFlag";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthStatus,
  selectClientSlug,
} from "./features/Authenticate/authenticateSlice";
import { selectProgress } from "./features/Results/resultsSlice";
import { BallTriangle } from "react-loader-spinner";
import {
  loadAggregates,
  loadHistory,
  loadQuestions,
  selectAggregates,
  selectQuestions,
} from "./features/Load/loadSlice";

const TESTING_ONLY =
  process.env.REACT_APP_PROJECT_ID === "dataleaders-dev-41e9c";

// Fallback loading component
const Loading = () => {
  return (
    <BallTriangle
      color="#5ba4b6"
      height={80}
      width={80}
      wrapperStyle={{ margin: "auto" }}
    />
  );
};

// Lazy load pages
const Authenticate = lazy(() => import("./pages/AuthenticatePage"));
const MainMenu = lazy(() => import("./pages/MainMenu"));
const SectionPage = lazy(() => import("./pages/SectionPage"));
const StrategyPage = lazy(() => import("./pages/StrategyPage"));
const ResultsPage = lazy(() => import("./pages/ResultsPage"));
const FourOhFour = lazy(() => import("./pages/404"));

function AppLayout() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
      id="pdf-root"
    >
      {TESTING_ONLY && <TestingFlag />}
      <PageHeader />
      <Box
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </Box>
      <PageFooter />
    </Container>
  );
}

function RequireAuth({ authenticated, children }) {
  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function App() {
  const dispatch = useDispatch();
  const clientSlug = useSelector(selectClientSlug);
  const { authenticated } = useSelector(selectAuthStatus);
  const { totalProgress } = useSelector(selectProgress);
  const { totalQuestions } = useSelector(selectQuestions);
  const { aggError } = useSelector(selectAggregates);

  useEffect(() => {
    if (authenticated && totalQuestions === 0) {
      dispatch(loadQuestions());
      dispatch(loadHistory(clientSlug));
      dispatch(loadAggregates());
    }
    // Not including dispatch in dep array.
    // eslint-disable-next-line
  }, [authenticated, clientSlug]);

  return (
    <Routes>
      <Route path="*" element={<FourOhFour />} />
      <Route element={<AppLayout />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Authenticate />
            </Suspense>
          }
        />
        <Route
          path="/assessment"
          element={
            <Suspense fallback={<Loading />}>
              <RequireAuth authenticated={authenticated}>
                <LinearProgress variant="determinate" value={totalProgress} />
                <MainMenu />
                <Snackbar open={aggError}>
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => dispatch(loadAggregates())}
                      >
                        Retry
                      </Button>
                    }
                  >
                    Benchmark data failed to load
                  </Alert>
                </Snackbar>
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="/assessment/:section"
          element={
            <Suspense fallback={<Loading />}>
              <RequireAuth authenticated={authenticated}>
                <LinearProgress variant="determinate" value={totalProgress} />
                <SectionPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="/assessment/strategy"
          element={
            <Suspense fallback={<Loading />}>
              <RequireAuth authenticated={authenticated}>
                <LinearProgress variant="determinate" value={totalProgress} />
                <StrategyPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="/results"
          element={
            <Suspense fallback={<Loading />}>
              <RequireAuth authenticated={authenticated}>
                <ResultsPage />
              </RequireAuth>
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
