import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import slugify from "slugify";

export const validateEmail = createAsyncThunk(
  "authenticate/validateEmail",
  async (email) => {
    const allEmails = await fetch(process.env.REACT_APP_DCM_AUTH_URL);

    if (allEmails.ok) {
      const data = await allEmails.json();
      const validEmail = data.findIndex((o) => o.Email === email) !== -1;
      return { validEmail };
    }
    throw allEmails;
  }
);

const authenticateSlice = createSlice({
  name: "authenticate",
  initialState: {
    formData: {
      completedBy: "",
      email: "",
      companyName: "",
    },
    clientSlug: "",
    authStatus: {
      authLoading: false,
      emailError: false,
      connectionError: false,
      authenticated: false,
    },
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData[action.payload.name] = action.payload.value;
    },
    setClientSlug: (state) => {
      state.clientSlug = slugify(state.formData.companyName, { lower: true });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateEmail.pending, (state) => {
        state.authStatus.authLoading = true;
        state.authStatus.emailError = false;
      })
      .addCase(validateEmail.fulfilled, (state, action) => {
        state.authStatus.authLoading = false;
        state.authStatus.emailError = !action.payload.validEmail;
        if (action.payload.validEmail) {
          state.authStatus.authenticated = true;
        }
      })
      .addCase(validateEmail.rejected, (state) => {
        state.authStatus.connectionError = true;
      });
  },
});

export const selectFormData = (state) => state.authenticate.formData;
export const selectAuthStatus = (state) => state.authenticate.authStatus;
export const selectClientSlug = (state) => state.authenticate.clientSlug;

export const { setClientSlug, setFormData } = authenticateSlice.actions;
export default authenticateSlice.reducer;
