import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageKey } from "../../utils";
import { saveProgress, setAggregate, setResults } from "../../utils/firebase";

export const uploadProgress = createAsyncThunk(
  "upload/uploadProgress",
  async ({ clientSlug, results }) => {
    await saveProgress(clientSlug, { currentProgress: results });
  }
);

export const uploadResults = createAsyncThunk(
  "upload/uploadResults",
  async ({ clientSlug, results }) => {
    await setResults(clientSlug, { ...results, currentProgress: {} });
  }
);

export const updateAggregates = createAsyncThunk(
  "upload/updateAggregates",
  async (aggs) => {
    await setAggregate(aggs);
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    isUploading: false,
    uploadError: false,
    answersLocked: false,
  },
  extraReducers: (builder) => {
    // Save Progress
    builder
      .addCase(uploadProgress.pending, (state) => {
        state.isUploading = true;
        state.uploadError = false;
      })
      .addCase(uploadProgress.fulfilled, (state) => {
        state.isUploading = false;
      })
      .addCase(uploadProgress.rejected, (state) => {
        state.isUploading = false;
        state.uploadError = true;
      });

    // Set loading whilst uploading Answers
    builder
      .addCase(uploadResults.pending, (state) => {
        state.isUploading = true;
        state.answersLocked = true;
      })
      .addCase(uploadResults.fulfilled, (state) => {
        state.isUploading = false;
        localStorage.removeItem(storageKey);
      })
      .addCase(uploadResults.rejected, (state) => {
        state.isUploading = false;
        state.answersLocked = false;
      });

    // Set loading whilst uploading Aggregate
    builder
      .addCase(updateAggregates.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(updateAggregates.fulfilled, (state) => {
        state.isUploading = false;
      });
  },
});

export const selectUploading = (state) => state.upload;
export const selectIsLocked = (state) => state.upload.answersLocked;

export default uploadSlice.reducer;
