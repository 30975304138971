import { configureStore } from "@reduxjs/toolkit";
import authenticateReducer from "./features/Authenticate/authenticateSlice";
import loadReducer from "./features/Load/loadSlice";
import resultsReducer from "./features/Results/resultsSlice";
import uploadReducer from "./features/Upload/uploadSlice";

export default configureStore({
  reducer: {
    authenticate: authenticateReducer,
    load: loadReducer,
    results: resultsReducer,
    upload: uploadReducer,
  },
});
