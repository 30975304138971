import { createSlice } from "@reduxjs/toolkit";
import { DOMAIN_TYPES } from "../../data/labels";
import { storageKey } from "../../utils";

const sectionCount = {};
const sectionObjects = {};

Object.keys(DOMAIN_TYPES).forEach((k) => {
  if (k !== "strategy") {
    sectionObjects[k] = {};
    sectionCount[k] = 0;
  }
});

const resultsSlice = createSlice({
  name: "results",
  initialState: {
    answers: {},
    priorities: {},
    scores: sectionObjects,
    results: {},
    progress: {
      totalProgress: 0,
      sectionProgress: sectionCount,
    },
    radarData: [],
    version: process.env.REACT_APP_DCM_VERSION,
  },
  reducers: {
    getFromStorage: (state, action) => {
      const storage = localStorage.getItem(storageKey);
      const currentProgress = action.payload;

      if (storage) {
        const storageJson = JSON.parse(storage);
        if (storageJson.version === process.env.REACT_APP_DCM_VERSION) {
          return storageJson;
        }
      } else if (
        currentProgress &&
        Object.keys(currentProgress).length > 0 &&
        currentProgress.version === process.env.REACT_APP_DCM_VERSION
      ) {
        return currentProgress;
      }
    },
    collectAnswer: (state, action) => {
      const { qid, answer, score, maxScore, section, totalQuestions } =
        action.payload;

      if (!Object.keys(state.answers).includes(qid)) {
        state.progress.totalProgress += 100 / totalQuestions;
        state.progress.sectionProgress[section] += 1;
      }

      state.answers[qid] = { ...answer, score };

      if (maxScore) {
        state.scores[section][qid] = {
          qScore: score,
          maxQScore: maxScore,
        };
      }

      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    collectPriorities: (state, action) => {
      const { name, value } = action.payload;
      state.priorities[name] = value;
      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    calculateResults: (state) => {
      const { scores } = state;

      Object.keys(scores).forEach((k) => {
        let totalScore = 0;
        let maxScore = 0;
        Object.keys(scores[k]).forEach((qid) => {
          totalScore += scores[k][qid].qScore;
          maxScore += scores[k][qid].maxQScore;
        });
        // Will produce a results which is a percentage of the questions that were answered
        state.results[k] = maxScore === 0 ? 0 : (totalScore / maxScore) * 100;
      });
    },
    formatRadarData: (state, action) => {
      const { versionSections, industry, aggData } = action.payload;

      // Format data for radar
      versionSections.forEach((section) => {
        if (section !== "details") {
          let sectionResults = {
            section: DOMAIN_TYPES[section],
            Results: Math.round(state.results[section]),
          };

          if (aggData.length > 0) {
            // Get industry and community scores
            const industryScores = aggData.find(
              (a) => a.agg_group === industry
            );
            const communityScores = aggData.find(
              (a) => a.agg_group === "Community"
            );

            if (industryScores && industryScores.contributor_count >= 5) {
              sectionResults["Industry"] = Math.round(industryScores[section]);
            }

            if (communityScores && communityScores.contributor_count >= 5) {
              sectionResults["Community"] = Math.round(
                communityScores[section]
              );
            }
          }

          state.radarData.push(sectionResults);
        }
      });
    },
  },
});

export const selectAnswers = (state) => state.results.answers;
export const selectPriorities = (state) => state.results.priorities;
export const selectProgress = (state) => state.results.progress;
export const selectResultsSlice = (state) => state.results;

export const {
  getFromStorage,
  collectAnswer,
  collectPriorities,
  calculateResults,
  formatRadarData,
} = resultsSlice.actions;
export default resultsSlice.reducer;
