// Firebase setup and services
import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const SHAREPOINT_API_BASE_URL = process.env.REACT_APP_SHAREPOINT_API_BASE_URL;

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});
const functions = getFunctions(firebaseApp);
// connectFunctionsEmulator(functions, "localhost", 5001); // Use local functions

export const getQuestions = async () => {
  const questionQuery = query(collection(db, "DCM_questions"));
  const snapshot = await getDocs(questionQuery);
  const dcmVersion = snapshot.docs
    .find((doc) => doc.id === process.env.REACT_APP_DCM_VERSION)
    .data();
  return { questions: dcmVersion.questions };
};

export const getHistory = async (clientSlug) => {
  const historyDoc = doc(db, "DCM_answers", clientSlug);
  const historySnapshot = await getDoc(historyDoc);
  if (!historySnapshot.exists()) {
    return {};
  } else {
    return historySnapshot.data();
  }
};

export const getAggregate = async (industry) => {
  const aggQuery = query(collection(db, "DCM_aggregate"));
  const aggSnapshot = await getDocs(aggQuery);
  const filteredData = aggSnapshot.docs.filter((doc) => {
    return doc.id === "global" || doc.id === industry;
  });
  const returnObj = {};
  filteredData.forEach((d) => {
    returnObj[d.id] = d.data();
  });
  return returnObj;
};

export const setAggregate = async (data) => {
  Object.keys(data).forEach((ag) => {
    setDoc(doc(db, "DCM_aggregate", ag), data[ag]);
  });
};

export const saveProgress = async (clientSlug, data) => {
  const checkDoc = await getDoc(doc(db, "DCM_answers", clientSlug));

  if (!checkDoc.exists()) {
    await setDoc(doc(db, "DCM_answers", clientSlug), data);
  } else {
    await updateDoc(doc(db, "DCM_answers", clientSlug), data);
  }
};

export const setResults = async (clientSlug, data) => {
  await setDoc(doc(db, "DCM_answers", clientSlug), data);
};

export async function uploadReportToSharepoint(blob, filename) {
  if (!SHAREPOINT_API_BASE_URL) {
    // URL not set on dev - Bail from trying to upload
    console.log("Dev account - not uploading");
    return;
  }

  const getAccessToken = httpsCallable(functions, "getAccessToken");
  let accessToken;

  try {
    accessToken = await getAccessToken();
    const url = `${SHAREPOINT_API_BASE_URL}${filename}:/content`;
    const requestHeaders = {
      Authorization: `Bearer ${accessToken.data}`,
      "Content-Type": "application/pdf",
    };
    const requestOptions = {
      method: "PUT",
      headers: requestHeaders,
      body: blob,
    };

    try {
      await fetch(url, requestOptions);
      console.log(`Data successfully sent to Sharepoint: ${filename}`);
    } catch (err) {
      console.error(`Error sending the user data to Sharepoint ${err.message}`);
    }
  } catch {
    accessToken = null;
  }
}

export async function getBigQueryAggregate() {
  const getAggregates = httpsCallable(functions, "getAggregates");

  try {
    const data = await getAggregates();
    return data.data;
  } catch (err) {
    console.error(`Error retrieving aggregate data\n${err.message}`);
    return { code: err?.message || "Error retrieving aggregate data" };
  }
}
