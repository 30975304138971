import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export default function PageHeader() {
  return (
    <PageHeaderContainer component="header">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: { xxs: "10px", xs: "30px" },
          ml: { xxs: "10px", xs: "20px" },
          height: "50px",
        }}
      >
        <img
          src="/Data-Leaders-Full-White-Logo.png"
          alt="logo"
          height="100%"
          width="100%"
          style={{ objectFit: "contain" }}
        />
      </Box>
      <PageHeaderTitle sx={{ color: "white", mr: { xxs: "10px", xs: "30px" } }}>
        <Typography component="h1" variant="h4" align="right">
          Data Capability Matrix
        </Typography>
      </PageHeaderTitle>
    </PageHeaderContainer>
  );
}

const PageHeaderContainer = styled(Box)`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  background: rgb(0, 0, 14);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 14, 1) 0%,
    rgba(0, 0, 14, 1) 9%,
    rgba(0, 18, 64, 1) 100%
  );
`;

const PageHeaderTitle = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin-right: 30px;
  border-right: 2px solid #038097;
  max-width: 50%;
`;
