import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DOMAIN_ORDER } from "../../data/labels";
import {
  getBigQueryAggregate,
  getHistory,
  getQuestions,
} from "../../utils/firebase";

export const loadQuestions = createAsyncThunk(
  "load/loadQuestions",
  async () => {
    const allQuestions = await getQuestions();
    return allQuestions;
  }
);

export const loadHistory = createAsyncThunk(
  "load/loadHistory",
  async (clientSlug) => {
    const history = await getHistory(clientSlug);
    return history;
  }
);

export const loadAggregates = createAsyncThunk(
  "load/loadAggregates",
  async (_, thunkApi) => {
    const aggregates = await getBigQueryAggregate();
    if (aggregates.code) {
      return thunkApi.rejectWithValue(aggregates.code);
    } else {
      return aggregates;
    }
  }
);

const loadSlice = createSlice({
  name: "load",
  initialState: {
    questions: {
      totalQuestions: 0,
      versionSections: [],
      sectionQuestions: {},
      questionsLoading: false,
      questionsError: false,
    },
    dcmHistory: {
      data: {},
      hasRestoredData: false,
      historyLoading: false,
      historyError: false,
    },
    aggregates: {
      data: [],
      aggLoading: false,
      aggError: false,
    },
  },
  reducers: {
    setHasRestoredData: (state) => {
      state.dcmHistory.hasRestoredData = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadQuestions.pending, (state) => {
        state.questions.questionsLoading = true;
        state.questions.questionsError = false;
      })
      .addCase(loadQuestions.fulfilled, (state, action) => {
        const allQuestions = action.payload;

        state.questions.sectionQuestions = allQuestions.questions;
        state.questions.versionSections = DOMAIN_ORDER.filter((d) =>
          Object.keys(allQuestions.questions).includes(d)
        );
        state.questions.questionsLoading = false;

        Object.keys(allQuestions.questions).forEach((s) => {
          state.questions.totalQuestions += Object.keys(
            allQuestions.questions[s]
          ).length;
        });
      })
      .addCase(loadQuestions.rejected, (state) => {
        state.questions.questionsLoading = false;
        state.questions.questionsError = true;
      });

    // Load History
    builder
      .addCase(loadHistory.pending, (state) => {
        state.dcmHistory.historyLoading = true;
        state.dcmHistory.historyError = false;
      })
      .addCase(loadHistory.fulfilled, (state, action) => {
        state.dcmHistory.data = action.payload;
        state.dcmHistory.hasRestoredData = false;
        state.dcmHistory.historyLoading = false;
      })
      .addCase(loadHistory.rejected, (state) => {
        state.dcmHistory.historyLoading = false;
        state.dcmHistory.historyError = true;
      });

    // Load Aggregates
    builder
      .addCase(loadAggregates.pending, (state) => {
        state.aggregates.aggLoading = true;
        state.aggregates.aggError = false;
      })
      .addCase(loadAggregates.fulfilled, (state, action) => {
        state.aggregates.aggLoading = false;
        state.aggregates.data = action.payload;
      })
      .addCase(loadAggregates.rejected, (state) => {
        state.aggregates.aggLoading = false;
        state.aggregates.aggError = true;
      });
  },
});

export const selectQuestions = (state) => state.load.questions;
export const selectHistory = (state) => state.load.dcmHistory;
export const selectAggregates = (state) => state.load.aggregates;

export const { setHasRestoredData } = loadSlice.actions;

export default loadSlice.reducer;
