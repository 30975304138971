import html2canvas from "html2canvas";

export const storageKey = "ZGNtLWZvcm0";

export async function convertToImage(rootElementId) {
  const input = document.getElementById(rootElementId);
  return html2canvas(input).then((canvas) => {
    return canvas.toDataURL("image/png");
  });
}

export function removeFromAverage(average, count, oldData) {
  if (count === 1) {
    return null;
  } else {
    const total = average * count;
    return (total - oldData) / (count - 1);
  }
}

export function newAverage(average, count, newData) {
  if (count === 0) {
    return newData;
  } else {
    const total = average * count;
    return (total + newData) / (count + 1);
  }
}
